import {
	FETCH_USER_REQUEST,
	FETCH_USER_SUCCESS,
	FETCH_USER_FAILURE,
	ADD_USER_REQUEST,
	ADD_USER_SUCCESS,
	ADD_USER_FAILURE,
	UPDATE_USER_REQUEST,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAILURE,
	DELETE_USER_REQUEST,
	DELETE_USER_SUCCESS,
	DELETE_USER_FAILURE,
} from "./userReducer";

import {
	FETCH_DATA_REQUEST,
	FETCH_DATA_SUCCESS,
	FETCH_DATA_FAILURE,
	ADD_APARTMENT,
	UPDATE_APARTMENT,
	DELETE_APARTMENT,
} from "./apartmentReducer";

import { SET_SNACKBAR, CLOSE_SNACKBAR } from "./snackbarReducer";
import { SET_USER, SET_USER_LOADING } from "./authReducer";
import { SET_HEADER_TITLE } from "./headerReducer";

import {
	FETCH_SETTINGS_REQUEST,
	FETCH_SETTINGS_SUCCESS,
	FETCH_SETTINGS_FAILURE,
	UPDATE_SETTINGS,
	SET_UPDATING_SETTINGS,
} from "./settingsReducer";

import { nanoid } from "nanoid";
import axios from "axios";
import { auth, firestore, storage } from "../firebase";
import { ADMIN_EMAILS } from "../config";

export const fetchUsers = () => {
	return async (dispatch) => {
		try {
			dispatch({ type: FETCH_USER_REQUEST });

			const idToken = await auth.currentUser.getIdToken();

			const { data } = await axios.get(
				"https://us-central1-development-management-52df8.cloudfunctions.net/api/users",
				{
					headers: {
						Authorization: `Bearer ${idToken}`,
					},
				}
			);

			dispatch({ type: FETCH_USER_SUCCESS, payload: data.users });
		} catch (error) {
			dispatch({ type: FETCH_USER_FAILURE, payload: error.message });
			dispatch({ type: SET_SNACKBAR, payload: error.message });
		}
	};
};

export const addUser = (user, onClose) => {
	return async (dispatch) => {
		try {
			dispatch({ type: ADD_USER_REQUEST });

			const idToken = await auth.currentUser.getIdToken();

			const { data } = await axios.post(
				"https://us-central1-development-management-52df8.cloudfunctions.net/api/users",
				user,
				{
					headers: {
						Authorization: `Bearer ${idToken}`,
					},
				}
			);

			dispatch({ type: ADD_USER_SUCCESS, payload: data.user });
			onClose();
		} catch (error) {
			dispatch({ type: ADD_USER_FAILURE, payload: error.message });
			dispatch({ type: SET_SNACKBAR, payload: error.message });
		}
	};
};

export const updateUser = (uid, updateData) => {
	return async (dispatch) => {
		try {
			dispatch({ type: UPDATE_USER_REQUEST });

			const idToken = await auth.currentUser.getIdToken();

			const { data } = await axios.patch(
				`https://us-central1-development-management-52df8.cloudfunctions.net/api/users/${uid}`,
				updateData,
				{
					headers: {
						Authorization: `Bearer ${idToken}`,
					},
				}
			);

			dispatch({ type: UPDATE_USER_SUCCESS, payload: data.user });
		} catch (error) {
			dispatch({ type: UPDATE_USER_FAILURE, payload: error.message });
			dispatch({ type: SET_SNACKBAR, payload: error.message });
		}
	};
};

export const deleteUser = (uid) => {
	return async (dispatch) => {
		try {
			dispatch({ type: DELETE_USER_REQUEST });

			const idToken = await auth.currentUser.getIdToken();

			await axios.delete(
				`https://us-central1-development-management-52df8.cloudfunctions.net/api/users/${uid}`,
				{
					headers: {
						Authorization: `Bearer ${idToken}`,
					},
				}
			);

			dispatch({ type: DELETE_USER_SUCCESS, payload: uid });
		} catch (error) {
			dispatch({ type: DELETE_USER_FAILURE, payload: error.message });
			dispatch({ type: SET_SNACKBAR, payload: error.message });
		}
	};
};

export const fetchApartments = (email) => {
	return async (dispatch) => {
		try {
			dispatch({ type: FETCH_DATA_REQUEST });
			let query = firestore.collection("apartments").orderBy("apt", "asc");

			if (!ADMIN_EMAILS.includes(email)) {
				query = query.where("allowedEmails", "array-contains", email);
			}

			const { docs } = await query.get();

			const data = docs.map((doc) => ({ id: doc.id, ...doc.data() }));
			// console.log(data);
			dispatch({ type: FETCH_DATA_SUCCESS, payload: data });
		} catch (error) {
			dispatch({ type: FETCH_DATA_FAILURE, payload: error.message });
			dispatch({ type: SET_SNACKBAR, payload: error.message });
		}
	};
};

export const addApartment = ({ id, data }) => {
	return { type: ADD_APARTMENT, payload: { id, data } };
};

export const updateApartment = ({ id, data }) => {
	return { type: UPDATE_APARTMENT, payload: { id, data } };
};

export const deleteApartment = (id) => {
	return { type: DELETE_APARTMENT, payload: id };
};

export const setSnackbar = (message) => {
	return { type: SET_SNACKBAR, payload: message };
};

export const closeSnackbar = (message) => {
	return { type: CLOSE_SNACKBAR };
};

export const setUser = (user) => {
	return { type: SET_USER, payload: user };
};

export const setUserLoading = (loading) => {
	return { type: SET_USER_LOADING, payload: loading };
};

export const setHeaderTitle = (title) => {
	return { type: SET_HEADER_TITLE, payload: title };
};

export const fetchPdfSettings = () => {
	return async (dispatch) => {
		try {
			dispatch({ type: FETCH_SETTINGS_REQUEST });
			const doc = await firestore
				.collection("settings")
				.doc("pdfsettings")
				.get();

			if (doc.exists) {
				const data = { id: doc.id, ...doc.data() };
				dispatch({ type: FETCH_SETTINGS_SUCCESS, payload: data });
			}
		} catch (error) {
			dispatch({ type: FETCH_SETTINGS_FAILURE, payload: error.message });
			dispatch({ type: SET_SNACKBAR, payload: error.message });
		}
	};
};

export const updateSettings = (data) => {
	return async (dispatch) => {
		try {
			dispatch({ type: SET_UPDATING_SETTINGS, payload: true });

			if (data.headerLogo?.file) {
				const path = `settings/${nanoid()}`;
				const { file } = data.headerLogo;
				const imageRef = storage.ref().child(path);
				await imageRef.put(file);
				const downloadUrl = await imageRef.getDownloadURL();
				data.headerLogo = downloadUrl;
			} else {
				delete data.headerLogo;
			}

			if (data.footerLogo?.file) {
				const path = `settings/${nanoid()}`;
				const { file } = data.footerLogo;
				const imageRef = storage.ref().child(path);
				await imageRef.put(file);
				const downloadUrl = await imageRef.getDownloadURL();
				data.footerLogo = downloadUrl;
			} else {
				delete data.footerLogo;
			}

			await firestore.collection("settings").doc("pdfsettings").update(data);

			dispatch({ type: UPDATE_SETTINGS, payload: data });
		} catch (error) {
			dispatch({ type: SET_SNACKBAR, payload: error.message });
		} finally {
			dispatch({ type: SET_UPDATING_SETTINGS, payload: false });
		}
	};
};
