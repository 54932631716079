export const SET_SNACKBAR = 'SET_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

const initialState = {
	isSnackbar: false,
	message: '',
};

export const snackbarReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_SNACKBAR:
			return {
				isSnackbar: true,
				message: payload,
			};

		case CLOSE_SNACKBAR:
			return {
				isSnackbar: false,
				message: '',
			};

		default:
			return state;
	}
};
