import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import { auth } from "../firebase";

const useStyles = makeStyles((theme) => ({
  buttonMarginRight: {
    marginRight: theme.spacing(2),
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const history = useHistory();

  const handleSignout = () => {
    auth.signOut();
    history.push("/signin");
  };
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar variant="dense">
        <Typography variant="h6" noWrap>
          The Spire
        </Typography>
        <Box flexGrow={1} />
        <Button
          color="inherit"
          onClick={handleSignout}
          startIcon={<ExitToAppIcon />}
        >
          Sign out
        </Button>
      </Toolbar>
    </AppBar>
  );
}
