import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import grey from "@material-ui/core/colors/grey";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchApartments,
  fetchPdfSettings,
  closeSnackbar,
  setUser,
  setUserLoading,
  fetchUsers,
} from "./store/actions";

import Loader from "./components/Loader";
import Layout from "./components/Layout";
import UserLayout from "./user/Layout";
import ProtectedRoute from "./components/ProtectedRoute";
import { auth } from "./firebase";

import { ADMIN_EMAILS } from "./config";

const SigninPage = lazy(() => import("./pages/signin"));
const DashboardPage = lazy(() => import("./pages/dashboard"));
const ApartmentPage = lazy(() => import("./pages/apartment"));
const ApartmentDetailsPage = lazy(() => import("./pages/apartment-details"));
const UserApartmentDetailsPage = lazy(() =>
  import("./user/ApartmentDetailsPage")
);
const UsersPage = lazy(() => import("./pages/users"));
const SettingsPage = lazy(() => import("./pages/settings"));
const UserDashboard = lazy(() => import("./pages/user-dashboard"));

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: grey[200],
    },
  },
}));

const App = () => {
  useStyles();
  const dispatch = useDispatch();
  const { loading, user } = useSelector((state) => state.auth);
  const { isSnackbar, message } = useSelector((state) => state.snackbar);

  const handleSnackbarClose = () => {
    dispatch(closeSnackbar());
  };

  React.useEffect(() => {
    auth.onAuthStateChanged((user) => {
      dispatch(setUser(user));
      dispatch(setUserLoading(false));
    });
  }, [dispatch]);

  React.useEffect(() => {
    if (user) {
      dispatch(fetchApartments(user.email));
      if (ADMIN_EMAILS.includes(user.email)) {
        dispatch(fetchPdfSettings());
        dispatch(fetchUsers());
      }
    }
  }, [dispatch, user]);

  if (loading) return <Loader />;

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Switch>
            {user ? ADMIN_EMAILS.includes(user?.email) ? (
                <Layout>
                  <Suspense fallback={<Loader />}>
                    <ProtectedRoute exact path="/" component={DashboardPage} />
                    <ProtectedRoute
                      exact
                      path="/apartments"
                      component={ApartmentPage}
                    />
                    <ProtectedRoute
                      exact
                      path="/apartments/:id"
                      component={ApartmentDetailsPage}
                    />
                    <ProtectedRoute exact path="/users" component={UsersPage} />
                    <ProtectedRoute
                      exact
                      path="/settings"
                      component={SettingsPage}
                    />
                  </Suspense>
                </Layout>
              ) : (
                <UserLayout>
                  <Suspense fallback={<Loader />}>
                    <ProtectedRoute exact path="/" component={UserDashboard} />
                    <ProtectedRoute
                      exact
                      path="/apartments/:id"
                      component={UserApartmentDetailsPage}
                    />
                  </Suspense>
                </UserLayout>
              ) : (
                <Route exact path="/" render={() => <Redirect to="/signin" />} /> 
              )}

            <Route exact path="/signin" component={SigninPage} />
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </Suspense>
      </BrowserRouter>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={isSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        message={message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
};

export default App;
