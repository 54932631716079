import * as React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = (props) => {
	const { user } = useSelector((state) => state.auth);

	if (!user) {
		return <Redirect to="/signin" />;
	}

	return <Route {...props} />;
};

export default ProtectedRoute;
