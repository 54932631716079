export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";
export const ADD_APARTMENT = "ADD_APARTMENT";
export const UPDATE_APARTMENT = "UPDATE_APARTMENT";
export const DELETE_APARTMENT = "DELETE_APARTMENT";

const initialState = {
	data: [],
	loading: false,
	error: null,
};

export const apartmentReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_DATA_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};

		case FETCH_DATA_SUCCESS:
			return {
				...state,
				data: payload,
				loading: false,
			};

		case FETCH_DATA_FAILURE:
			return {
				...state,
				error: payload,
				loading: false,
			};

		case ADD_APARTMENT:
			return {
				...state,
				data: [...state.data, { id: payload.id, ...payload.data }],
			};

		case UPDATE_APARTMENT:
			return {
				...state,
				data: state.data.map((item) => {
					if (item.id !== payload.id) return { ...item };
					else return { ...item, ...payload.data };
				}),
			};

		case DELETE_APARTMENT:
			return {
				...state,
				data: state.data.filter((item) => item.id !== payload),
			};

		default:
			return state;
	}
};
