export const SET_USER = "SET_USER";
export const SET_USER_LOADING = "SET_USER_LOADING";

const initialState = {
	user: null,
	loading: true,
};

export const authReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_USER:
			return {
				...state,
				user: payload,
			};

		case SET_USER_LOADING:
			return {
				...state,
				loading: payload,
			};

		default:
			return state;
	}
};
