export const FETCH_SETTINGS_REQUEST = "FETCH_SETTINGS_REQUEST";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILURE = "FETCH_SETTINGS_FAILURE";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const SET_UPDATING_SETTINGS = "SET_UPDATING_SETTINGS";

const initialState = {
	loading: false,
	updating: false,
	error: null,
	data: null,
};

export const settingsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_SETTINGS_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};

		case FETCH_SETTINGS_SUCCESS:
			return {
				...state,
				data: payload,
			};

		case FETCH_SETTINGS_FAILURE:
			return {
				...state,
				error: payload,
			};

		case UPDATE_SETTINGS:
			return {
				...state,
				data: { ...state.data, ...payload },
			};

		case SET_UPDATING_SETTINGS:
			return {
				...state,
				updating: payload,
			};

		default:
			return state;
	}
};
