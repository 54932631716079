export const SET_HEADER_TITLE = "SET_HEADER_TITLE";

const initialState = {
	title: "Dashboard",
};

export const headerReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_HEADER_TITLE:
			return {
				...state,
				title: payload,
			};

		default:
			return state;
	}
};
