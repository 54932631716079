export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

const initialState = {
	data: [],
	error: null,
	loading: false,
	adding: false,
	updating: false,
	deleting: false,
};

export const userReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_USER_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};

		case FETCH_USER_SUCCESS:
			return {
				...state,
				data: payload,
				loading: false,
			};

		case FETCH_USER_FAILURE:
			return {
				...state,
				error: payload,
				loading: false,
			};

		case ADD_USER_REQUEST:
			return {
				...state,
				adding: true,
			};

		case ADD_USER_SUCCESS:
			return {
				...state,
				data: [...state.data, { ...payload }],
				adding: false,
			};

		case ADD_USER_FAILURE:
			return {
				...state,
				adding: false,
			};

		case UPDATE_USER_REQUEST:
			return {
				...state,
				updating: true,
			};

		case UPDATE_USER_SUCCESS:
			return {
				...state,
				data: state.data.map((item) => {
					if (item.uid !== payload.uid) return { ...item };
					else return { ...item, ...payload };
				}),
				updating: false,
			};

		case UPDATE_USER_FAILURE:
			return {
				...state,
				updating: false,
			};

		case DELETE_USER_REQUEST:
			return {
				...state,
				deleting: true,
			};

		case DELETE_USER_SUCCESS:
			return {
				...state,
				data: state.data.filter((item) => item.uid !== payload),
				deleting: false,
			};

		case DELETE_USER_FAILURE:
			return {
				...state,
				deleting: false,
			};

		default:
			return state;
	}
};
