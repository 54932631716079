import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";

import { store } from "./store";
import { Provider } from "react-redux";

const theme = createTheme({
  mixins: {
    toolbar: {
      minHeight: 48,
      "@media (min-width:0px) and (orientation: landscape)": {
        minHeight: 48,
      },
      "@media (min-width:720px)": {
        minHeight: 48,
      },
    },
  },
  palette: {
    primary: blue,
  },
  props: {
    MuiAppBar: {
      elevation: 1,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
