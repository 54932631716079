import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';

function Loader() {
  return (
    <>
      <CssBaseline />
      <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    </>
  );
}

export default Loader;
