import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { authReducer } from './authReducer';
import { userReducer } from './userReducer';
import { headerReducer } from './headerReducer';
import { snackbarReducer } from './snackbarReducer';
import { apartmentReducer } from './apartmentReducer';
import { settingsReducer } from './settingsReducer';

const rootReducers = combineReducers({
  auth: authReducer,
  user: userReducer,
  header: headerReducer,
  snackbar: snackbarReducer,
  apartment: apartmentReducer,
  settings: settingsReducer,
});

export const store = createStore(rootReducers, applyMiddleware(thunk));
