import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { auth } from '../firebase';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  buttonMarginRight: {
    marginRight: theme.spacing(2),
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const history = useHistory();
  const { title } = useSelector((state) => state.header);

  const handleSignout = () => {
    auth.signOut();
    history.push('/signin');
  };
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar variant="dense" style={{ backgroundColor: 'white', color: 'black' }}>
        <Typography variant="h6" noWrap>
          {title}
        </Typography>
        <Box flexGrow={1} />
        <Button
          target="_blank"
          href="https://aaron-peru-website.firebaseapp.com/"
          className={classes.buttonMarginRight}
          color="inherit"
          startIcon={<VisibilityIcon />}
        >
          View Live
        </Button>
        <Button color="inherit" onClick={handleSignout} startIcon={<ExitToAppIcon />}>
          Sign out
        </Button>
      </Toolbar>
    </AppBar>
  );
}
