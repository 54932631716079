import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const app = firebase.initializeApp({
  apiKey: 'AIzaSyD98gPnPZTC2MfVMQf1QDQG5CGQTHfW8aY',
  authDomain: 'development-management-52df8.firebaseapp.com',
  projectId: 'development-management-52df8',
  storageBucket: 'development-management-52df8.appspot.com',
  messagingSenderId: '25355786262',
  appId: '1:25355786262:web:50bf79fe94adf28577bd37',
});

export const auth = app.auth();
export const firestore = app.firestore();
export const storage = app.storage();

// auth.useEmulator('http://localhost:9099');
